import React, { Component } from 'react'
import { f_cur, f_class } from "helpers/formatter"
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

export class Price extends Component {
  render(){
    const { prod } = this.props
    let currency = f_cur(prod.currency);
    return (
      <React.Fragment>
        <span>{prod.price}</span>
        <span>{currency}</span>
      </React.Fragment>
    )
  }
}

export class OldPrice extends Component {
  render(){
    const { prod } = this.props
    let currency = f_cur(prod.currency);
    return (
      <React.Fragment>
        <span>{prod.oldprice}</span>
        <span>{currency}</span>
      </React.Fragment>
    )
  }
}

class PricesBox extends Component {
  render() {
    let i18n = this.props.labels;
    const { prod, priceStyle, oldPriceStyle, pricesStyle, colLabelStyle, colPriceStyle } = this.props
    const pricesStyle_f = f_class(pricesStyle,'col-12 text-left')
    const colLabelStyle_f = f_class(colLabelStyle,'col col-md-6 col-lg-4')
    const colPriceStyle_f = f_class(colPriceStyle,'col')
    const priceStyle_f = f_class(priceStyle,'h3')
    const oldPriceStyle_f = f_class(oldPriceStyle)
    return (
      <div className="c-price">
        <div className="row">
          <div className={pricesStyle_f}>
            <div className="row align-items-end text-muted">
              <div className={colLabelStyle_f}>
                {i18n.old_price}
              </div>
              <div className={colPriceStyle_f}>
                <span className={"text-nowrap prod-oldprice "+oldPriceStyle_f}>
                  <del><OldPrice prod={prod} /></del>
                </span>
              </div>
            </div>
          </div>
          <div className={pricesStyle_f}>
            <div className="row mt-lg-2 align-items-end text-success">
              <div className={colLabelStyle_f}>
                <strong>
                  {i18n.new_price}
                </strong>
              </div>
              <div className={colPriceStyle_f}>
                <span className={"text-nowrap prod-price "+priceStyle_f+" text-success"}>
                  <strong><Price prod={prod} /></strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    labels: state.labels.list,
  }
}
export default connect(mapStateToProps)(withRouter(PricesBox));
