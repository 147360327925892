export const encodeStrInUrl = (string)=>{
  let replaced = string.replace(/[. ,:-]+/g, "-")
  let replaced2 = replaced.replace(/["'()]/g, "")
  let lowered = replaced2.toLowerCase()
  let encoded = encodeURI(lowered)
  return encoded
}

export const getPath = (lang)=>{
  const basePath = '/'
  const langPath = lang ? lang+'/' : ':lang?/'
  let objs = {
    home:      basePath,
    lang:      basePath+langPath,
    prodsbase: basePath+langPath+'prods',
    prods:     basePath+langPath+'prods',
    prodbase:  basePath+langPath+'prod',
    prod:      basePath+langPath+'prod/:slug/:id/:trackid?',
    category:   basePath+langPath+'prods/:category_name/:category_id/:trackid?',
    relateds:  basePath+langPath+'relateds/:id/:trackid?',
    error404:  '/error/404' // Importante: non cambiare il link, o cambiarlo anche in forms/info.php
  }
 return objs;
}

export const getProdLink = (lang,prod)=>{
  let path = getPath(lang)
  let prodName = encodeStrInUrl(prod.name);
  let prodPath = path.prodbase+'/'+prodName+'/'+prod.id;
  let prodLink = {pathname: prodPath, state: prod.id };
  return prodLink;
}

export const getProdLinkImg = (lang,prod)=>{
  const { REACT_APP_IMGS } = process.env
  let prodImg = prod.code ? REACT_APP_IMGS+"prods/"+prod.slug_id : '';
  return prodImg;
}

export const getCategLink = (lang,prod)=>{
  let path = getPath(lang)
  let prodCat = encodeStrInUrl(prod.category_name);
  let prodPath = path.prodsbase+'/'+prodCat+'/'+prod.category_id;
  let prodLink = {pathname: prodPath, state: prod.id };
  return prodLink;
}
