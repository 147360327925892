import './style.scss';
import React, { Component } from 'react';
import { connect } from "react-redux";
import Cat from 'components/Banner/Category'
import { getPath } from "helpers/getPath"
import withTemplate from 'templates/'
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

class Home extends Component {
  render() {
    const { REACT_APP_NAME } = process.env
    const { categories, loading, lang, i18n } = this.props
    let path = getPath(lang)
    let menuElems = loading ? [] : categories.map((cat,i) => ({
                                      link: path.prods+'/'+cat.label+'/'+cat.id,
                                      name: cat.label,
                                      id: cat.id
                                    }))
  const menuElem = menuElems.length>0 ? menuElems.map(function(elem,index){
    let isMain = index==0 || index==3 || index==4
    let classes
    switch(index) {
      case 0:
      case 3:
        classes = ''
      break;
      case 1:
      case 2:
        classes = 'w-md-50'
      break;
      case 4:
        classes = 'order-9'
      break;
      default:
        classes = 'w-md-50'
        //classes = 'w-md-50 w-lg-33'
    }
    return <Cat id={"cat_"+elem.id}
             title={elem.name}
             link={elem.link}
             align={"center"}
             img={elem.id}
             classes={'c-banner--with-opacity '+classes}
             key={index}
             cta={i18n.go_to_products}
             type={isMain ? 'main' : 'standard'}
          />;
  }) : ''

    return (
      <div className="p-home d-flex flex-wrap w-100">
        <Helmet>
          <title>{REACT_APP_NAME}</title>
          <meta name="description" content={i18n.site_description} />
        </Helmet>
        {menuElem}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { categories } = state
  return {
    loading: categories.loading,
    categories: categories.list,
    i18n: state.labels.list,
    lang: state.i18n.lang,
  }
}
export default connect(mapStateToProps)( withRouter(withTemplate(Home)) );
