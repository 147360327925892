import './style.scss';
import React, { Component } from 'react'
import { connect } from "react-redux";
import { iframeFormLink } from "helpers/iframeFormLink"

class Form extends Component {
  render() {
    const { prod, tracker } = this.props
    let linkIframe = iframeFormLink(prod,tracker)
    const heightIframe = '300px';
    return (
      <div className="c-form">
        {/*<div id="frmCallApi">&nbsp;</div>*/}
        <div className="frm-call-api"></div>
        <iframe title="form-order"
                src={linkIframe}
                frameBorder="0"
                width="100%" height={heightIframe}
                style={{ height: heightIframe }}
                className="c-form__iframe">
        </iframe>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tracker: state.tracker,
  }
}
export default connect(mapStateToProps)(Form);
