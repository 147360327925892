import './style.scss';
import React, { Component } from 'react';
import Header from './parts/Header/'
import Footer from './parts/Footer/'
import { f_class } from "helpers/formatter"
import { cookiesInit } from "helpers/cookies"
import { fetchCategs, setLang, fetchUserLocation, fetchLabels } from 'state/actions'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

// DECORATOR
// ES: https://benmccormick.org/2019/02/11/reusable-react

const withTemplate = WrappedComponent => {
   class Template extends Component {
     componentDidMount() {
       // porto in alto lo scroll ad ogni cambio pagina
       window.scrollTo(0, 0);
       cookiesInit(this.props)
       // controlla prima se esiste la lang nel path.
       const langInPath = this.props.location.pathname.split("/")[1] // legge il path attuale
       const langInMatch = this.props.match.params.lang // legge il path in match
       const lang = langInMatch ? langInMatch : langInPath
       if( lang ) {
         this.updateAll(lang)
       } else {
         // Se non c'è chiamo la location (passando la lang di default dello stato)
         // lascio il controllo dei dati a componentDidUpdate()
         const defaultLang = this.props.lang;
         this.updateAll(defaultLang)
         this.props.dispatch( fetchUserLocation(defaultLang) )
       }
     }

     componentDidUpdate(prevProps){
       const { lang, country_code } = this.props
       if( ( country_code || country_code !== undefined ) && country_code !== lang ) {
         this.props.dispatch(fetchCategs(country_code))
         this.props.dispatch(setLang(country_code))
         this.props.dispatch(fetchLabels(country_code))
       }
     }

     updateAll(lang) {
       this.props.dispatch(setLang(lang))
       this.props.dispatch(fetchCategs(lang))
       this.props.dispatch(fetchLabels(lang))
     }

     render() {
       const { fullwidth, className } = this.props
         return (
           <div>
             <section className={"t-template "+( !!fullwidth ? ' t-template--fullwidth' : '' )+f_class(className)}>
               <Header match={this.props.match} />
               {!fullwidth}
               <div className={"t-main "+( !fullwidth ? 'container px-3 pt-4 pb-3' : 'px-0' )}>
                 <WrappedComponent {...this.props} {...this.callbacks} />
               </div>
               <hr className="mt-0" />
               <Footer />
             </section>
          </div>
         )
     }
  }

  const mapStateToProps = (state) => {
    return {
      lang: state.i18n.lang,
      country_code: state.userloc.list.country_code,
    }
  }

  return connect(mapStateToProps)( withRouter(Template) ); // withRouter(Template);
};

export default withTemplate
