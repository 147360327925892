import './style.scss';
import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';
import PricesBox from 'components/Prices'
import ProdImage from 'pages/Prods/Prod/parts/Image/index.js'
import TrustabilityBadges from "components/TrustabilityBadges"
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

class Infos extends Component {
  render(){
    const i18n = this.props.labels;
    const { prod, openModalHandler, modalInDesktop, isSticked } = this.props
    const modalInDesktopStyle = (!modalInDesktop ? ' d-lg-none ' : '')
    const cardStyle = " card w-100 mw-100 p-3 "
    return (
      <React.Fragment>
          <div className="row c-info">
              {/* titolo mobile */}
              <div className="col-12 d-block d-sm-none">
                <h1 className="text-break m-0 p-0">{prod.name}</h1>
                <h5 className="mt-3 mb-4">{ReactHtmlParser(prod.claim)}</h5>
              </div>
              <div className="col-12 col-sm-5">
                <ProdImage prod={prod} useLink={true} useBkg={true} imgStyle={"c-image XXX-c-image--bordered mb-0 mt-0 mt-sm-2 mb-sm-2"} notLazy={true} />
              </div>
              <div className="col-12 col-sm-7">
                  {/* titolo tablet e desktop */}
                  <div className="d-none d-sm-block">
                    <h1>{prod.name}</h1>
                    <h5>{ReactHtmlParser(prod.claim)}</h5>
                    <hr />
                  </div>
                  <div className="d-none d-sm-block"> {ReactHtmlParser(prod.description)} </div>
              </div>
          </div>
          <div className={cardStyle+" card--no-border-bottom card--full-bkg mt-3 "+modalInDesktopStyle}>
              <div className="row">
              <div className="col-12 px-3 col-sm-9 px-sm-0 col-md-7 mx-auto">
                <PricesBox prod={prod} colLabelStyle="col-7 col-sm-5 text-left" colPriceStyle="col x-text-right" />
              </div>
              </div>
          </div>
          <div className={cardStyle+" card--no-border-top card--full-bkg mb-3 pt-0 "+modalInDesktopStyle+" "+( (isSticked) ? " sticky-top sticky-top--sticked " : '' )}>
             <button className="btn btn-block btn-lg btn-success mx-auto"
                     onClick={openModalHandler}
                     style={{'maxWidth':'400px'}} >
               {i18n.order_now_cta}
             </button>
          </div>
          <div className="d-block d-lg-none" >
            <TrustabilityBadges itemStyle={'is-icons-empty'} />
            <hr />
          </div>
          <div className="mt-3 d-block d-sm-none"> {ReactHtmlParser(prod.description)} </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    labels: state.labels.list,
  }
}
export default connect(mapStateToProps)(withRouter(Infos));
