import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class CustomLink extends Component {
  render(){
  return ( typeof this.props.to === 'string' && this.props.to.substring(0, 1) !== "/" )
                ? <a {...this.props} href={this.props.to} >{this.props.children}</a>
                : <Link {...this.props}>{this.props.children}</Link>
    }
}
