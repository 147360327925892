import './style.scss';
import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

class Form extends Component {
  render(){
    const i18n = this.props.labels;
    return (
      <div className="c-relateds_thanks text-center ">
        <h1>{i18n.thanks_after_order}</h1>
        <hr />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tracker: state.tracker,
    labels: state.labels.list,
  }
}
export default connect(mapStateToProps)(withRouter(Form));
