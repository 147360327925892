import './style.scss';
import React from 'react'
import Box from './Item'

export default (props) => {
    const { prod, openModalHandler, fullwidth } = props
    const Boxes = (prod && prod.boxes) &&
                    prod.boxes.map((box, i) => (
                      <Box box={box} prod={prod} key={i} fullwidth={fullwidth} openModalHandler={openModalHandler} />
                    ));
    return (
      <section className={"c-box__wrapper pb-3 pt-0 pt-md-3 "+( fullwidth ? "mb-4 pb-4" : '' )}>
        {Boxes}
      </section>
    )
}
