export default function labels(state, action) {
    switch (action.type) {
        case 'SET_LABELS_LIST':
            return {
                ...state,
                loading: false,
                list:  action.payload.list
            }
        default:
            return state;
    }
}
