//import './style.scss';
import React, { Component } from 'react'
import Link from "components/Link/";
import { getProdLink } from "helpers/getPath"
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { f_class } from "helpers/formatter"

// NOTE: Per poter ricaricare il componente del prodotto anche se lo richiamo
// da se stesso (come nel caso dei relatedProd ,che devono ricaricare lo stesso
// componente ma con dati differenti) ho bisogno di passare nel link uno "state"
// ( es: <Link to={pathname: prodPath, state: prod.id } ... ) e controllare
// questo state con il "componentWillReceiveProps", utilizzando il location.state
// per monitorare quando questo stato cambia e richiedere di nuovo fetchProd().

class ProdLink extends Component {
  render(){
    const { prod, linkStyle, lang } = this.props
    let prodLink = getProdLink(lang,prod)
    let props = {...this.props}; // salvo una copia dell'array
    // elimino ciò che non devo passare al child
    delete props.staticContext;
    delete props.dispatch;
    return(
      <React.Fragment>
        <Link to={prodLink}
          {...props}
          className={"c-prod-link "+f_class(linkStyle)} >
          {this.props.children}
        </Link>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
  }
}
export default connect(mapStateToProps)(withRouter(ProdLink));
