import axios from 'axios'

const { REACT_APP_API } = process.env

export function setLoading(status) {
    return {
      type: 'SET_LOADING',
      status
    }
}

export function setLang(lang) {
  return {
    type: 'SET_LANG',
    payload: {
      lang
    }
  }
}

export function setTracker(tracker) {
  return {
    type: 'SET_TRACKID',
    payload: {
      tracker
    }
  }
}

export function fetchProds(lang,cat) {
  const langQuery = ( lang !== undefined && lang !== 'undefined' && lang !== '' ) ? '&lang='+lang : ''
  const catQuery = ( cat !== undefined && cat !== 'undefined' && cat !== '' ) ? '&cat='+cat : ''
  const apiUrl = REACT_APP_API+'product/read.php?'+langQuery+catQuery;
  return (dispatch, getState) => {
    axios.get(apiUrl).then((resp) => {
        dispatch({
            type: 'SET_PRODS',
            payload: {
              list: resp.data.records,
              error: resp.data.error,
              loading: false
            }
        });
    }, error => {
      dispatch({
          type: 'SET_PRODS',
          payload: {
              list: [],
              error: 'error',
              loading: false
          }
      });
    })
  };
}

export function fetchProd(prodId,lang) {
  const langQuery = '&lang='+lang //( lang !== undefined && lang !== 'undefined' && lang !== '' ) ? '&lang='+lang : ''
  const apiUrl = REACT_APP_API+'product/read_one.php?id='+prodId+langQuery;
  return (dispatch, getState) => {
    axios.get(apiUrl).then((resp) => {
        dispatch({
            type: 'SET_PROD',
            payload: {
              list: resp.data.record,
              error: resp.data.error,
              loading: false
            }
        })
    }, error => {
      dispatch({
          type: 'SET_PROD',
          payload: {
              list: [],
              error: 'error',
              loading: false
          }
      })
    })
    // CATCH PER EVITARE ERRORE IN CASO DI ELEMENTO NON ESISTENTE (da verificare)
    .catch(error => {
      dispatch({
          type: 'SET_PROD',
          payload: {
              list: [],
              error: 'error',
              loading: false
          }
      })
    })
  }
}

export function fetchLabels(lang) {
  const apiUrl = REACT_APP_API+'label/read_one.php?lang='+lang;
  return (dispatch, getState) => {
    axios.get(apiUrl).then((resp) => {
        dispatch({
            type: 'SET_LABELS_LIST',
            payload: {
              list: resp.data.record
            }
        })
    }, error => {
      dispatch({
          type: 'SET_LABELS_LIST',
          payload: {
              list: []
          }
      })
    })
  }
}

export function fetchCategs(lang) {
  const apiUrl = REACT_APP_API+'category/read.php?lang='+lang;
  return (dispatch, getState) => {
    axios.get(apiUrl).then((resp) => {
        dispatch({
            type: 'SET_CATEGORIES_LIST',
            payload: {
              list: resp.data.records
            }
        })
    }, error => {
      dispatch({
          type: 'SET_CATEGORIES_LIST',
          payload: {
              list: []
          }
      })
    })
  }
}

export function fetchCateg(lang,cat) {

  const catQuery = ( cat !== undefined && cat !== 'undefined' && cat !== '' ) ? '&id='+cat : ''
  const langQuery = ( lang !== undefined && lang !== 'undefined' && lang !== '' ) ? '&lang='+lang : ''

  const apiUrl = REACT_APP_API+'category/read_one.php?'+catQuery+langQuery;

  //console.log('apiUrl',apiUrl);

  return (dispatch, getState) => {
    axios.get(apiUrl).then((resp) => {
        dispatch({
            type: 'SET_CATEGORY',
            payload: {
              list: resp.data.record,
              label: resp.data.record.label
            }
        })
    }, error => {
      dispatch({
          type: 'SET_CATEGORY',
          payload: {
            list: [],
            label: ''
          }
      })
    })
    // CATCH PER EVITARE ERRORE IN CASO DI ELEMENTO NON ESISTENTE (da verificare)
    .catch(error => {
      dispatch({
          type: 'SET_CATEGORY',
          payload: {
            list: [],
            label: ''
          }
      })
    })
  }
}

export function fetchUserLocation(lang) {
  const langQuery = ( lang !== undefined && lang !== 'undefined' && lang !== '' ) ? '?lang='+lang : ''
  const apiUrl = REACT_APP_API+'current_user_country/read.php'+langQuery;
  return (dispatch, getState) => {
    axios.get(apiUrl).then((resp) => {
        dispatch({
            type: 'SET_USER_LOCATION',
            payload: {
              list: resp.data
            }
        })
    }, error => {
      dispatch({
          type: 'SET_USER_LOCATION',
          payload: {
              list: {"country_code":lang}
          }
      })
    })
  }
}
