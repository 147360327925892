import './style.scss';
import React, { Component } from 'react'

export default class BtnUp extends Component {
  scrollToTop(){
    window.scrollTo(0, 450)
  }
  render() {
    return (
      <div className="btn-go-up d-inline-block d-sm-none" onClick={this.scrollToTop}>
        <i className="XXX-fas XXX-fa-chevron-up icon-chevron-up"></i>
      </div>
    )
  }
}
