export default function prod(state, action) {
    switch (action.type) {
        case 'SET_PROD':
            return {
                ...state,
                loading: action.payload.loading,
                list:  action.payload.list,
                error: action.payload.error
            }
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.status,
            }
        default:
            return state;
    }
}
