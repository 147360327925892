import { getCookie } from "helpers/cookies"
import { parseIfJsonString } from "helpers/object_and_json"

export const iframeFormLink = (prod,tracker)=>{
  /* PER RIPRENDERE LA FORM:
     /forms/generator/?network=wf&lang=it&product=htf_footfix&bodypadding=0px&fontsize=16px&marginvert=1&btnbkg=%2328a745&btncolor=%23ffffff&btnradius=3px&btnalign=center&inputborder=1px+solid+rgba%280%2C0%2C0%2C.125%29&inputheight=2em&inputpadding=1em+0.5em+&hidelabel=1&footerhide=1
  */

  let resultLink, startLink, trackLink, formParams
  const { trackname, trackid, lanname, lanid } = tracker
  // inizio del link (path delle api + pagina alla form del network corretto)
  startLink = process.env.REACT_APP_FORM+"?network=form_" + prod.network
  // se esiste il traker, lo aggiunge all'url nella querystring
  trackLink = ( trackname && trackid ) ? "&" + trackname + "=" + trackid : ''
  // aggiunge i parametri principali
  formParams = "&network=" + prod.network
  formParams += "&lang=" + prod.lang
  formParams += "&product=" + prod.code
  // se esiste lan, lo aggiunge come subid all'url nel campo dei parametri
  formParams += ( lanname && lanid ) ? ("&" + lanname + "=" + lanid) : ''

  // aggiunge la grafica:
  // creo una stringa con tutti i parametri settati in dinamicDesignParams()
  let designParamsList = dinamicDesignParams()
  Object.keys(designParamsList).map((p, i) => {
    formParams += '&'+p+'='+designParamsList[p]
    return true
  })
  // faccio encoding della query dei caratteri trovati perchè vadano nell value di "&params"
  formParams = encodeURIComponent(formParams)

  // monta il link
  resultLink = startLink + trackLink + "&params=" + formParams
  return resultLink
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// VARIABILI PRIVATE (non esportate)

const dinamicDesignParams = ()=>{
  let defaultParams = {
    bodypadding:"0px",
    fontsize:"16px",
    btnsize:"18px",
    marginvert:"1",
    btnbkg: "#e87d0e",
    btncolor:"#ffffff",
    btnradius:"3px",
    btnalign:"center",
    inputborder:"1px solid rgba(0,0,0,.125)",
    inputheight:"2em",
    inputpadding:"1em 0.5em",
    hidelabel:"1",
    footerhide:"1",
    btnlabel:"Procedi", // NOTE: momentanea. TODO: generalizzare nell'i18n globale
    tnkstolink:"/it/relateds/55#/"
  }

  // ora che ho creato il link di default, faccio un replace coi parametri nei cookies abTest
  const abTest = parseIfJsonString( getCookie('abTest') );
  Object.keys( abTest ).map((p, i) => {
    console.log('p',p,defaultParams[p],abTest[p]);
    if( abTest[p] ) defaultParams[p] = abTest[p]
    return true
  })

  // !!!! QUI STAMPARE IL NUOVO OGGETTO

  return defaultParams;
}
