import React, { Component } from 'react'
import Aside from 'components/Aside'
import PricesBox from 'components/Prices'
import TrustabilityBadges from "components/TrustabilityBadges/"
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Form from './Form/'

class AsideProd extends Component {
  render(){
  const i18n = this.props.labels;
  const { prod, showForm, openModalHandler } = this.props
  const btn = <button className="mt-3 btn btn-block btn-lg btn-success"
                         onClick={openModalHandler}> {i18n.order_now_cta}
                </button>
  const form = <div className="pt-4">
                      <Form prod={prod} />
                  </div>
  return (
        <Aside>
            <h4 className="mb-2">{i18n.order_now_cta} {prod.name}</h4>
            <hr />
            <PricesBox prod={prod}
                    colLabelStyle="col-7 text-left "
                    colPriceStyle="col text-right " />
            { showForm ? form : btn }
            <hr />
            <TrustabilityBadges itemStyle={'is-icons-empty'} />
        </Aside>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    labels: state.labels.list,
  }
}
export default connect(mapStateToProps)(withRouter(AsideProd));
