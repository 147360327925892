import React, { Component } from 'react'
import ProdFull from './ProdFull'
import Loading from 'pages/Loading'
import { connect } from "react-redux";
import { getVisibleProd } from 'state/selectors'
import { fetchProd, setLoading } from 'state/actions'
import withTemplate from 'templates/'
import { Redirect } from 'react-router-dom'

class Prod extends Component {

  componentWillReceiveProps(nextProps) {
    const nextProp = nextProps.location.state
    // esci dalla funzione se non soddisfa le caratteristiche (evita l'uso di if/else)
    if ( nextProp === undefined || nextProp === this.props.prod.id )  return
    // risetto il loader a true per attendere il nuovo prodotto
    this.props.dispatch( setLoading(true) )
    // se non esce, esegue la funzione
    window.scrollTo(0, 0)
    const { params } = this.props.match

    this.props.dispatch( fetchProd( params.id, params.lang ) )
  }

  componentDidMount() {
    const { params } = this.props.match

    this.props.dispatch( fetchProd( params.id, params.lang ) )
  }

  componentWillUnmount() {
    // risetto il loader a true per attendere il nuovo prodotto
    this.props.dispatch( setLoading(true) )
  }

  // se il prodotto non esiste, faccio redirect alla home
  renderRedirect = () => {
    const { prod } = this.props
    if ( ! prod || prod === undefined || prod.length <= 0 ) {
      return <Redirect to='/' />
    }
  }
  render(){
    let i18n = this.props.labels;
    const { prod, loading, currency } = this.props
    if (loading) { return ( <Loading /> ) }
    return (
      <div className="p-prod">
        {this.renderRedirect()}
        <ProdFull prod={prod} currency={currency} title={i18n.also_interested} />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { currency, prod } = state
  return {
    prod: getVisibleProd(state),
    currency,
    loading: prod.loading,
    labels: state.labels.list,
  }
}
export default connect(mapStateToProps)( withTemplate(Prod) );
