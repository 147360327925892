import './style.scss';
import React from 'react'
import ReactHtmlParser from 'react-html-parser';
import ProdLink from "pages/Prods/Prod/parts/Link"
import Schema from "pages/Prods/Prod/parts/Schema"
import { Price, OldPrice } from "components/Prices"
import ProdImage from 'pages/Prods/Prod/parts/Image/index.js'

const Row = (props) => {
    const { prod, className } = props
    let className_f = className ? className : ''
    let prices = <div className="pt-2 pb-1">
                  <strong className="text-body h4 text-nowrap"> <Price prod={prod} /> </strong>
                  <s className="text-muted text-nowrap"> <OldPrice prod={prod}  /> </s>
                </div>
    return (
      <div className='c-prods__list--row col-12'>
        <Schema prod={prod}>
          <div className={'c-row row '+className_f}>
            <div className='col-4 col-sm-3 col-md-3 col-lg-2 mb-3 mb-sm-0 mx-auto pr-0'>
              <ProdImage prod={prod} size={'thumb'} useBkg={true} imgStyle={"c-image XXX-c-image--bordered"} useLink={true} />
            </div>
            <div className='col'>
              <ProdLink prod={prod} className="card-title">
                <span className="h4 text-primary d-block d-sm-none text-break" >{prod.name}</span>
                <span className="h3 text-primary d-none d-sm-block text-break" >{prod.name}</span>
              </ProdLink>
              <div className="d-none d-md-block">
                {prices}
                <p className="card-subtitle my-2 text-break">{ReactHtmlParser(prod.claim)}</p>
                <div className='card-text text-break'>{ReactHtmlParser(prod.description)}</div>
              </div>
              <div className="d-block d-md-none">
                {prices}
                <p className="my-2 text-break">{ReactHtmlParser(prod.claim)}</p>
              </div>
            </div>
          </div>
          {/*<hr />*/}
        </Schema>
      </div>
    )
}

export default Row
