import React, { Component } from 'react'
import Link from "components/Link/";
import { getPath } from "helpers/getPath"
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

class MgsError extends Component {
  render(){
  const i18n = this.props.labels;
  const path = getPath(i18n.lang)
  const { REACT_APP_REACT } = process.env // link "corretto" (in caso di errore CORS)
  const { error } = this.props
  const absoluteLinkToLang = (REACT_APP_REACT+path.lang.substring(1))
  let msgError = error==='nores' ? i18n.items_no_results : i18n.error_loading_result
  return (
      <div className="p-prod__empty text-center">
        <p>{msgError}</p>
        <Link className='btn btn-primary' to={absoluteLinkToLang}>
          {i18n.home}
        </Link>
      </div>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    labels: state.labels.list,
  }
}
export default connect(mapStateToProps)(withRouter(MgsError));
