import React from 'react';
import './style.scss';

const modal = (props) => {
    return (
      <div className="c-modal modal" role="dialog" style={{ display: props.show ? 'block' : 'none' }}>
        <div className="modal-overlay" onClick={props.close}></div>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{props.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.close}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {props.children}
            </div>
            {/*<div className="modal-footer">
              <button type="button" className="btn btn-primary">Save changes</button>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>*/}
          </div>
        </div>
      </div>
    )
}

export default modal;
