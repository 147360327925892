import filters from './filters'
import category from './category'
import categories from './categories'
import prods from './prods'
import prod from './prod'
import labels from './labels'
import tracker from './tracker'
import i18n from './i18n'
import userloc from './userloc'

// TODO: non va messo così. necessario refactor per il get del path da browser.
// let url = window.location.href
// let path = url.split("//")[1] ? url.split("//")[1] : url.split("//")[0]
// let pathLang = path.split("/")[1] ? path.split("/")[1] : 'en';

const initialState = {
  currency: "€",
  tracker: {},
  category: {
    loading: true,
    list: [],
  },
  categories: {
    loading: true,
    list: []
  },
  labels: {
    list: [],
  },
  prods: {
    loading: true,
    list: [],
    error: false
  },
  prod: {
    loading: true,
    list: [],
    error: false
  },
  filters: {
    amount: 0,
    sortBy: 'id',
    sortDir: 'ASC'
  },
  i18n: {
    lang: 'en',
  },
  userloc: {
    list: [],
  }
}

export default function app(state = initialState, action) {
  return {
    currency: state.currency,
    i18n: i18n(state.i18n, action),
    tracker: tracker(state.tracker, action),
    category: category(state.category, action),
    categories: categories(state.categories, action),
    labels: labels(state.labels, action),
    prods: prods(state.prods, action),
    prod: prod(state.prod, action),
    filters: filters(state.filters, action),
    userloc: userloc(state.userloc, action),
  }
}
