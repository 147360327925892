import React, { Component } from 'react'
import Item from 'pages/Prods/Lists/Card/'

export default class Related extends Component {
  render(){
    const { prod } = this.props
    let Product = ( prod.id !== undefined ) ? <Item prod={prod} className="c-related" hideClaim={true} hidePrice={false} /> : ''
    return (
      <React.Fragment>
        {Product}
      </React.Fragment>
    )
  }
}
