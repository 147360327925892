import './style.scss';
import React, { Component } from 'react'
import Image from 'components/Image'
import { getProdLink, getProdLinkImg } from "helpers/getPath"
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { f_class } from "helpers/formatter"

class ProdImage extends Component {
  render(){
    const lang = this.props.lang;
    const { prod, size, imgStyle, useLink, linkStyle, notLazy } = this.props
    const sizeName = size ? '_'+size : ''
    let prodImg = getProdLinkImg(lang,prod)+'/prod'+sizeName+'.png'
    let prodLink = useLink ? getProdLink(lang,prod) : false
    return(
      <Image src={prodImg}
             alt={prod.name}
             imgStyle={'c-prod-image '+imgStyle}
             link={prodLink}
             linkStyle={'c-prod-image__wrapper '+f_class(linkStyle)}
             notLazy={notLazy} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
  }
}
export default connect(mapStateToProps)(withRouter(ProdImage));
