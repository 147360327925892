import React, { Component } from 'react';
import { connect } from "react-redux";
import { getVisibleProds } from 'state/selectors'
import MgsError from 'components/MsgError/'
import Item from 'pages/Prods/Lists/Row/'
import { setLang, fetchProds, setLoading, fetchCateg } from 'state/actions'
import Loading from 'pages/Loading'
import withTemplate from 'templates/'
import Cat from 'components/Banner/Category'
import { Helmet } from "react-helmet";
import { f_ucfirst } from "helpers/formatter"
import { withRouter } from 'react-router-dom';

class Prods extends Component {

  componentDidMount () {
    const { category_id, lang } = this.props.match.params;
    const currLang = lang ? lang : this.props.lang;
    this.updateAll( currLang, category_id )
  }

  componentWillReceiveProps(nextProps) {
    const nextProp = nextProps.location.state
    const { category_id, lang } = this.props.match.params;
    const currLang = lang ? lang : this.props.lang;
    // esci dalla funzione se non soddisfa le caratteristiche (evita l'uso di if/else)
    if ( nextProp === undefined || nextProp === category_id )  return
    // risetto il loader a true per attendere il nuovo prodotto
    this.props.dispatch( setLoading(true) )
    // se non esce, esegue la funzione
    window.scrollTo(0, 0)
    this.updateAll( currLang, nextProp )
  }

  componentWillUnmount() {
    // risetto il loader a true per attendere il nuovo prodotto
    this.props.dispatch( setLoading(true) )
  }

  updateAll = ( lang, cat ) => {
    this.props.dispatch(setLang(lang))
    this.props.dispatch(fetchProds(lang,cat))
    this.props.dispatch(fetchCateg(lang,cat))
  }

  render() {
    const i18n = this.props.labels;
    const { REACT_APP_NAME } = process.env
    const { prods, loading, error, category } = this.props
    const { category_id } = this.props.match.params
    // prende la traduzione dalla lingua dell'url
    const category_name = category.label;
    if (loading) { return ( <Loading /> ) }
    const prodotto = ( error )
                        ? <MgsError error={error} />
                        : prods.map((prod) => ( <Item prod={prod} key={prod.id} /> ))
    const title = category_name ? <div className="pb-4">
                              <Cat id={"cat_"+category_id}
                                   title={category_name}
                                   align={"center"}
                                   img={category_id}
                                   classes={' mini-height c-banner--with-opacity '}
                              />
                            </div> : <br />;
    return (
      <div className="p-prods">
        <Helmet>
          <title>{f_ucfirst(category_name)+' || '+REACT_APP_NAME}</title>
          <meta name="keywords" content={category_name+','+REACT_APP_NAME} />
          <meta name="description" content={f_ucfirst(category_name)+': '+i18n.site_description} />
        </Helmet>
        { title }
        <section className={"c-prods__wrapper "+(category_name ? 'container' : '')}>
          { prodotto }
        </section>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { currency, prods, category } = state
  return {
    prods: getVisibleProds(state),
    category: category.list,
    currency,
    loading: prods.loading,
    error: prods.error,
    labels: state.labels.list,
    lang: state.i18n.lang,
  }
}

export default connect(mapStateToProps)(withTemplate(withRouter(Prods)));
