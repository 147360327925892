import React, { Component } from 'react'
import withTemplate from 'templates/'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

class Page404 extends Component {
  render(){
    const i18n = this.props.labels;
    return (
      <div className='p-page404'>
        <div className='text-center'>
          <h5> {i18n.error} 404 </h5>
          <h3> {i18n.page_not_found} </h3>
          <hr />
          <p> {i18n.page_not_found_or_removed} </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    labels: state.labels.list,
  }
}
export default connect(mapStateToProps)(withTemplate(withRouter(Page404)));
