import React, { Component } from 'react'
import Link from "components/Link/";
import { f_ucfirst } from "helpers/formatter"
import { withRouter } from 'react-router-dom';

class Breadcrumb extends Component {
  render() {
    /* NOTE: pattern deve essere un array di oggetti così composto:
    [{name:'nomelink',link:'urllink'}, {name:'nomelink',link:'urllink'}]; */
    const { pattern, destination } = this.props
    const linkList = pattern.map((p,i) => <Link className="breadcrumb-item" to={p.link} key={i}>{f_ucfirst(p.name)}</Link>)
    return (
      <div className="t-breadcrumb">
        <div className="breadcrumb">
          {/*<Link className="breadcrumb-item" to={getPath(lang).home}>Home</Link>*/}
          {linkList}
          <span className="breadcrumb-item">{destination}</span>
        </div>
      </div>
    )
  }
}

export default withRouter(Breadcrumb);
