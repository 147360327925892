import './style.scss';
import React from 'react'
import Icon from 'components/Icon/'

const TrustabilityBadges = (props) => {
  return (
      <div className="c-trustability-badge my-3 col col-6 col-md">
        <Icon icon={props.badge.icon}
              text={props.badge.text}
              iconStyle='' />
      </div>
  )
}
export default TrustabilityBadges
