import React, { Component } from 'react'
import { f_cur } from "helpers/formatter"

import { JsonLd } from "react-schemaorg";
import { WithContext, Person } from "schema-dts";
import { getProdLinkImg } from "helpers/getPath"

export default class Schema extends Component {
  render(){
    const { prod } = this.props
    let currency = f_cur(prod.currency);

    const size = false;
    const sizeName = size ? '_'+size : ''

    let prodImg = getProdLinkImg('it',prod)+'/prod'+sizeName+'.png'

    const jsonLd_prod: WithContext<Person> = {
        "@context": "http://schema.org",
        "@type": "Product",
        "url": prod.url,
        "category": prod.category_name,
        "name": prod.name,
        "description": prod.description,
        "image": prodImg,
        "brand": process.env.REACT_APP_NAME,
        "sku": prod.slug,
        "offers": {
            "@type": "Offer",
            "price": prod.price,
            "priceCurrency": currency,
            "availability": 'InStock',
            "url": prod.url,
        }
    };

    return (
      <React.Fragment>
        <JsonLd item={jsonLd_prod}/>
        <div itemProp="offers" itemScope itemType="http://schema.org/Offer" {...this.props}>
          <span itemProp="category" content={prod.category_name}></span>
          <span itemProp="url" content={prod.url}></span>
          <span itemProp="name" content={prod.name}></span>
          <span itemProp="description" content={prod.claim}></span>
          <span itemProp="description" content={prod.description}></span>
          <span itemProp="price" content={prod.price}></span>
          <span itemProp="priceCurrency" content={currency}></span>
          {this.props.children}
        </div>
      </React.Fragment>
    )
  }
}
