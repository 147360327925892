export default function tracker(state, action) {
    switch (action.type) {
        case 'SET_TRACKID':
        return {
            ...state,
            ...action.payload.tracker
        }
        default:
            return state;
    }
}
