import React from 'react'

export default (props) => {
    return (
      <aside className="c-aside sticky-top mb-3">
        <div className="card card--full-bkg w-100 mw-100">
          <div className="card-body">
            {props.children}
          </div>
        </div>
      </aside>
    )
}
