
export function getVisibleProds(state) {
    const { prods } = state
    let prodsList = prods.list
    return prodsList
}

export function getVisibleProd(state) {
    const { prod } = state
    let prodList = prod.list
    return prodList
}

export function getFilteredProds(prods,amount) {
    if (!amount) return prods
    return prods.filter(prod => prod.amount > amount).sort()
}

export function getSortedProds(prods,sortDir,sortBy) {
    let compareFunction
    if( ! sortBy ) return prods
    // l'array temporaneo contiene oggetti con posizione e valore di ordinamento
    var mapped = prods.map((el)=> {
      return { index: el[sortBy], value: el };
    })
    // sorting tramite le compareFunctions
    switch(sortDir) {
      case 'DESC':
        compareFunction = function(a,b) {
          if (a.index > b.index) return -1;
          if (a.index < b.index) return 1;
          return 0;
        }
      break;
      default:
        compareFunction = function(a,b) {
          if (a.index > b.index) return 1;
          if (a.index < b.index) return -1;
          return 0;
        }
      break;
    }
    // ordinamento dell'array mappato contenente i valori ridotti
    if(compareFunction) {
      mapped.sort(compareFunction);
    } else {
      mapped.sort();
    }
    // rimappatura dell'array nell'ordine sortato
    var result = mapped.map((el)=>el.value);
    // ritorna il nuovo array
    return result
}
