export const f_cur =(currency)=>{
  let currencyFormatted;
  switch (currency) {
   case 'eur':
   currencyFormatted = '€'
   break;
   case 'usd':
   currencyFormatted = '$'
   break;
   default:
     currencyFormatted = currency
 }
 return currencyFormatted;
}

export const f_class =(className,defaultClass)=>{
  return className ? ' '+className+' ' : defaultClass ? ' '+defaultClass+' ' : '';
}

export const f_ucfirst =(name)=>{
  return name ? name.charAt(0).toUpperCase() + name.slice(1) : ''
}
