import './style.scss';
import React, { Component } from 'react'
import Link from "components/Link/";
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';

// PRIVATES (non esportati)

class TagImg extends Component {
  render(){
    const { src, alt, className, notLazy } = this.props
    const imgStyle = "c-image c-image--tag img-fluid "+className
    const img = notLazy ? <img className={imgStyle} alt={alt} src={src} />
                        : <LazyLoadImage className={imgStyle+" c-image--lazyed"} alt={alt} src={src} />
    return (
      <React.Fragment>{img}</React.Fragment>
    )
  }
}

class BkgImg extends Component {
  render(){
    let { src, alt, className, bkgsize, ratio } = this.props
    // calcolo il ratio (se presente la prop). Usare frazioni. (es: 2/3, 4/5...)
    let n = ratio && ratio.split("/");
    let ratioPercent = ( n && ! isNaN(n[0]) && ! isNaN(n[1]) ) ? ((n[0]/n[1])*100)+'%' : false
    let imgProps = {
      alt,
      className: 'c-image c-image--bkg '+className,
      style: {
        ...this.props.style,
        backgroundImage: "url("+src+")",
        backgroundSize: bkgsize ? bkgsize : 'contain',
        paddingTop : ratioPercent ? ratioPercent : '100%'
      }
    }
    return (
      <div {...imgProps}></div>
    )
  }
}

// PUBLIC

class Image extends Component {
  render() {
    const { src, alt, imgStyle, link, linkStyle, useBkg, bkgsize, ratio, notLazy } = this.props
    let imgProps = {
      src,
      alt: (!!alt ? alt : ''),
      className: 'Image '+(!!imgStyle ? imgStyle : ''),
    }
    // valuto se stampare l'immagine in tag o in background
    let imageObj = (!!useBkg) ? <BkgImg {...imgProps} bkgsize={bkgsize} ratio={ratio} />
                              : <TagImg {...imgProps} notLazy={notLazy} />

    let linkObj = link && <Link to={link} className={"Link "+(!!linkStyle ? linkStyle : '')}> {imageObj} </Link>
    // valuto se stampare l'immagine o il link che wrappa l'immagine
    let result = linkObj ? linkObj : imageObj
    return (
      <React.Fragment>
        {result}
      </React.Fragment>
    )
  }
}

export default trackWindowScroll(Image);
