import React, { Component } from 'react'
import ProdFull from './ProdFull'
import MgsError from 'components/MsgError/'
import Loading from 'pages/Loading'
import { connect } from "react-redux"
import { getVisibleProd } from 'state/selectors'
import { fetchProd, setLoading } from 'state/actions'
import withTemplate from 'templates/'
import { f_ucfirst } from "helpers/formatter"
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';

class Prod extends Component {

  componentDidMount() {
    const { id, lang } = this.props.match.params;
    const currLang = lang ? lang : this.props.lang;
    this.updateAll( currLang, id )
  }

  componentWillReceiveProps(nextProps) {
    const nextProp = nextProps.location.state
    const { id, lang } = this.props.match.params;
    const currLang = lang ? lang : this.props.lang;
    // esci dalla funzione se non soddisfa le caratteristiche (evita l'uso di if/else)
    if ( nextProp === undefined || nextProp === this.props.prod.id )  return
    // setto il loader a true per attendere il nuovo prodotto
    this.props.dispatch( setLoading(true) )
    // se non esce, esegue la funzione
    window.scrollTo(0, 0)
    this.updateAll( currLang, id )
  }

  componentWillUnmount() {
    // setto il loader a true per attendere il nuovo prodotto
    this.props.dispatch( setLoading(true) )
  }

  updateAll = ( lang, id ) => {
    this.props.dispatch( fetchProd( id, lang ) )
  }

  render() {
    const { prod, loading, currency, error, i18n } = this.props
    const { REACT_APP_NAME } = process.env
    if (loading) { return ( <Loading /> ) }
    // setto il layout del prodotto
    const Prodotto = ( error || (prod && prod.length > 0) || ! prod )
                        ? <MgsError error={error} />
                        : <ProdFull prod={prod} currency={currency} />
    // const prodName = prod && prod.name ? f_ucfirst(prod.name) : '';
    // const prodDesc = prod && prod.description ? prod.description : '';
    // const prodCatName = prod && prod.category_name ? prod.category_name : '';

    const HelmetTags = ( ! prod )
                    ?  <Helmet>
                         <title>{REACT_APP_NAME}</title>
                         <meta name="description" content={i18n ? i18n.site_description : REACT_APP_NAME } />
                       </Helmet>
                    :  <Helmet>
                         <title>{f_ucfirst(prod.name)+' - '+ f_ucfirst(prod.category_name) +' || '+REACT_APP_NAME}</title>
                         <meta name="description" content={prod.description} />
                         <meta name="keywords" content={prod.name+','+prod.category_name+','+REACT_APP_NAME} />
                       </Helmet>
    return (
      <div className="p-prod">
        {HelmetTags}
        {Prodotto}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { currency, prod, i18n } = state
  return {
    prod: getVisibleProd(state),
    currency,
    loading: prod.loading,
    error: prod.error,
    lang: i18n.lang,
  }
}
export default connect(mapStateToProps)( withTemplate(withRouter(Prod)) );
