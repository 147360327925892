import './style.scss';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from 'pages/Home'
//import About from 'pages/About'
import Prods from 'pages/Prods'
import Prod from 'pages/Prods/Prod/'
import Relateds from 'pages/Prods/Relateds/'
import Page404 from 'pages/Page404'
import { getPath } from "helpers/getPath"

class App extends Component {
  render() {
    let callbacks = {}
    let path = getPath(this.props.lang)
    return (
      <Router>
        <div className="">
          <Switch>
            <Route exact path={path.home} render={ props => <Home {...props} {...callbacks} fullwidth={true} /> } />
            <Route exact path={path.lang} render={ props => <Home {...props} {...callbacks} fullwidth={true} /> } />
            <Route exact path={path.prods} render={ props => <Prods {...props} {...callbacks} /> } />
            <Route exact path={path.category} render={ props => <Prods {...props} {...callbacks} fullwidth={true} /> } />
            <Route path={path.prod} render={ props => <Prod {...props} {...callbacks} /> } />
            <Route path={path.relateds} render={ props => <Relateds {...props} {...callbacks} /> } />
            {/*<Route path={path.lang+'about'} render={ props => <About {...props} {...callbacks} /> } />*/}
            <Route exact path={path.error404} render={ props => <Page404 {...props} {...callbacks} status={404} /> } />
            <Route render={ props => <Page404 {...props} {...callbacks} status={404} /> } />
          </Switch>
        </div>
      </Router>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
  }
}
export default connect(mapStateToProps)(withRouter(App));
