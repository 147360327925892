import './style.scss';
import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Link from "components/Link/";
import Nav from './Nav'
import { getPath } from "helpers/getPath"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowing: false
    }
  }
  toggleModalHandler = () => {
    this.state.isShowing ? this.closeModalHandler() : this.showModalHandler()
  }
  showModalHandler = () => {
    this.setState({ isShowing: true });
  }
  closeModalHandler = () => {
    this.setState({ isShowing: false });
  }
  render() {
    const { REACT_APP_NAME } = process.env
    const lang = this.props.lang
    let path = getPath( lang )
    return (
      <nav className="t-header navbar sticky-top navbar-expand-lg navbar-light bg-light">
        <div className="container px-0">
          <Link to={path.lang}
                className="navbar-brand btn btn-link pl-0 pl-md-3 font-weight-normal">
            {/*<i className="mr-1 icon-fire"></i>*/}
            <span>{REACT_APP_NAME}</span>
          </Link>
          <div
                onClick={this.toggleModalHandler}
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
            </span>
          </div>
          <div className={"collapse navbar-collapse "+( this.state.isShowing ? 'show' : '' )}
               id="navbarSupportedContent">
          <Nav
            match={this.props.match}
            listClass="navbar-nav bg-light"
            elemsClass="nav-item"
            linksClass="nav-link"
            closeModalHandler={this.closeModalHandler}
          />
          </div>
        </div>
      </nav>
    )
  }
}
//export default withRouter(Header)
const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
  }
}
export default connect(mapStateToProps)( withRouter(Header) );
