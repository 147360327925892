import React from 'react'

export default (props) => {
  const defaultClasses = " w-100 d-flex align-items-center "
  const Icon = <i className={" fa "+props.icon}></i>
  const Text = <div className={"c-icon__wrapper"+defaultClasses+props.iconStyle}>
                  <div className="c-icon"> {Icon} </div>
                  <div className="c-icon-text ml-3"> {props.text} </div>
                </div>
  return (
    <React.Fragment> {Text ? Text : Icon} </React.Fragment>
  )
}
