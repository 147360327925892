import React, { Component } from 'react'
import { connect } from "react-redux";
import Link from "components/Link/";
import { getPath } from "helpers/getPath"
//import { fetchLabels } from 'state/actions'

class Nav extends Component {
  render(){
    const lang = this.props.lang
    const { listClass, elemsClass, linksClass, categories, category, loading, closeModalHandler } = this.props
    let path = getPath( lang )
    let activeCateg = category.id ? category.id : false;
    // se le categorie sono state caricate, allora mappale e crea l'array per la lista del menu
    let menuElems = loading ? [] : categories.map((cat,i) => (
                                    { link: path.prods+'/'+cat.label+'/'+cat.id,
                                      text: cat.label,
                                      state: cat.id,
                                      active: cat.id === activeCateg ? 'active' : ''
                                    }
                                  ));
    const menuElem = menuElems.map((elem,index) => (
      <li className={elemsClass} key={index}>
        <Link onClick={closeModalHandler}
              className={linksClass+' '+elem.active} to={ {pathname: elem.link, state: elem.state } } >
          {elem.text}
        </Link>
      </li>
    ))
    return(
      <ul className={'t-nav '+listClass}>
        {menuElem}
      </ul>
    )
  }
}
const mapStateToProps = (state) => {
  const { categories, category, labels, i18n } = state
  return {
    loading: categories.loading,
    categories: categories.list,
    category: category.list,
    labels: labels.list,
    lang: i18n.lang,
  }
}
export default connect(mapStateToProps)( Nav );
