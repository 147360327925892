export default function category(state, action) {
    switch (action.type) {
        case 'SET_CATEGORY':
            return {
                ...state,
                loading: false,
                list:  action.payload.list,
                label:  action.payload.list.label
            }
        default:
            return state;
    }
}
