import React, { Component } from 'react'
import Related from './Item'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

class Relateds extends Component {
  render(){
    const i18n = this.props.labels;
    const { prod, openModalHandler, title } = this.props
    const currTitle = title ? title : i18n.related_prods
    let Relateds = ''
    let RelatedsTitle = ''

    if(prod && prod.relateds && typeof prod.relateds.map === "function" ) {
        RelatedsTitle = <h3 className="text-center pt-2 pb-4">
                          {currTitle}
                        </h3>
        Relateds = prod.relateds.map((p, i) => (
                                <Related prod={p}
                                         key={i}
                                         openModalHandler={openModalHandler} />
                            ));
      } else {
        console.error('Problemi su prod.relateds');
      }
    return (
      <React.Fragment>
        {(prod && prod.relateds) ?
          <section className="c-related__wrapper">
              {RelatedsTitle}
            <div className="row related__wrapper justify-content-center">
              {Relateds}
            </div>
          </section>
        : '' }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tracker: state.tracker,
    labels: state.labels.list,
  }
}
export default connect(mapStateToProps)(withRouter(Relateds));
