import './style.scss';
import React from 'react'
import ProdImage from 'pages/Prods/Prod/parts/Image/index.js'
import ProdLink from 'pages/Prods/Prod/parts/Link'
import { f_class } from "helpers/formatter"
import { Price, OldPrice } from "components/Prices"
import Schema from "pages/Prods/Prod/parts/Schema"

const Card = (props) => {
  const { prod, className, hideClaim, hidePrice } = props
  return (
    <article className="c-prods__list--card c-card h-100 mb-3 px-3 col-12 col-sm-6 col-md-4 col-lg-3">
    <div className="card">
      <Schema prod={prod} className={"col-6 col-md-3 col-lg-3 pb-4 text-center "+f_class(className)}>
        <div className="p-3">
          <ProdImage prod={prod} size={'thumb'} useBkg={true} useLink={true} />
        </div>
        <div className="card-body pt-0 pb-0">
          <ProdLink prod={prod}>
            <h5 className="card-title text-primary text-break p-0 m-0">{prod.name}</h5>
          </ProdLink>
          <div className={'pt-2' + ( hidePrice ? ' d-none d-md-block' : '' ) }>
            <strong className="text-body h4 text-nowrap"> <Price prod={prod} /> </strong>
            <s className="text-muted text-nowrap"> <OldPrice prod={prod} /> </s>
          </div>
          <p className={'card-text pt-2 small ' + ( hideClaim ? ' d-none d-md-block' : '' ) }>
            {prod.claim}
          </p>
        </div>
      </Schema>
    </div>
    </article>
  )
}

export default Card
