import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import Item from './Item'

class Badges extends Component {
  render(){
    let i18n = this.props.labels;
    const { itemStyle } = this.props
    const b = i18n.trustability_badges
    const badges = [
      {
        text: b[0],
        icon: 'icon-send-o'
      },{
        text: b[1],
        icon: 'icon-handshake'
      },{
        text: b[2],
        icon: 'icon-banknote'
      },{
        text: b[3],
        icon: 'icon-clipboard'
      }
    ]
    const Items = (badges) &&
                    badges.map((badge, i) => (
                      <Item badge={badge} key={i} />
                    ));
    return (
      <section className={"c-trustability-badge__wrapper "+itemStyle+" row"}>
        {Items}
      </section>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    labels: state.labels.list,
  }
}
export default connect(mapStateToProps)(withRouter(Badges));
