export const getTracker = (q)=>{
  let trackname, trackid, lanname, lanid
  // setta il giusto tracklid recuperato dalla querystring
  // (sceglie tra quello di google o quello di microsoft)
  if (!!q.gclid) {
    trackname = 'gclid'
    trackid = q.gclid
  } else if(!!q.msclkid) {
    trackname = 'msclkid'
    trackid = q.msclkid
  } else if(!!q.crosslid) {
    trackname = 'crosslid'
    trackid = q.crosslid    
  } else {
    trackname = false
    trackid = false
  }
  // il parametro lan in querystring, se presente,
  // deve essere passato alla form come subid1.
  if (!!q.lan) {
    lanname = 'subid1'
    lanid = q.lan
  } else {
    lanname = false
    lanid = false
  }
  return {
    trackname,
    trackid,
    lanname,
    lanid
  }
}
