export default function userloc(state, action) {
    switch (action.type) {
        case 'SET_USER_LOCATION':
        return {
            ...state,
            list:  action.payload.list
        }
        default:
            return state;
    }
}
