import React, { Component } from 'react'
import { connect } from "react-redux";
import Infos from './parts/Infos/'
import Form from './parts/Form/'
import Boxes from './parts/Boxes/'
import Faqs from './parts/Faqs/'
//import Review from './parts/Review'
import Related from './parts/Related/'
import Aside from './parts/Aside'
import Modal from 'components/Modal/';
import BtnUp from './parts/BtnUp/'
import Schema from "pages/Prods/Prod/parts/Schema"
import Breadcrumb from 'templates/parts/Breadcrumb/'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { getCategLink } from "helpers/getPath"
import { withRouter } from 'react-router-dom';

class ProdFull extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowing: false
    }
  }
  preorder = () => {
    //console.log('preorder')
  }
  openModalHandler = () => {
    this.setState({ isShowing: true });
    this.preorder(); // lancia l'azione di trigger del preorder
  }
  closeModalHandler = () => {
    this.setState({ isShowing: false });
  }
  render(){
    let i18n = this.props.labels;
    let lang = this.props.lang;
    const { prod } = this.props
    let categLink = getCategLink(lang,prod).pathname
    const modal = <Modal prod={prod}
                       show={this.state.isShowing}
                       close={this.closeModalHandler}
                       title={i18n.order_now_cta+' '+prod.name}>
                      <Form prod={prod} />
                  </Modal>
    return (
          <div className="p-prod__full">
            {modal}
            <Breadcrumb pattern={[{name:prod.category_name,link:categLink}]} destination={prod.name} />
            <Schema prod={prod} className="row">
              <div className="col-12 col-lg-8">
                <Infos prod={prod} openModalHandler={this.openModalHandler} />
                <div className="btn-go-up-wrapper">
                  <BtnUp />
                  <Boxes prod={prod} openModalHandler={this.openModalHandler} />
                  <Faqs prod={prod} />
                  <BtnUp />
                </div>
              </div>
              <div className="col d-none d-lg-block">
                <Aside prod={prod} showForm={false} openModalHandler={this.openModalHandler} />
              </div>
            </Schema>
            <LazyLoadComponent>
              <hr />
              <Related prod={prod} />
            </LazyLoadComponent>
          </div>
        )
  }
}

const mapStateToProps = (state) => {
  return {
    labels: state.labels.list,
    lang: state.i18n.lang,
  }
}
export default connect(mapStateToProps)(withRouter(ProdFull));
