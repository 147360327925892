export default function i18n(state, action) {
    switch (action.type) {
        case 'SET_LANG':
        return {
            ...state,
            lang:  action.payload.lang
        }
        default:
            return state;
    }
}
