import './style.scss';
import React, { Component } from 'react';
import Link from "components/Link/";

class Banner extends Component {
  render() {
    const { id, title, link, align, children, img, type, classes, cta } = this.props
    const isMain = type=='main';
    const titleBox = title && <h1 className="main-title">{title}</h1>
    const ctaBox = cta ? <Link to={link} id={id} className='btn btn-success' >{cta}</Link> : ''
    const childBox = children && <div className="mt-4 child-box w-md-75 w-lg-50">{children} {ctaBox}</div>
    const imgUrl = process.env.REACT_APP_IMGS+"categories/"+img+".jpg"
    const mainImgUrl = process.env.REACT_APP_IMGS+"categories/"+img+"_main.jpg"
    const c = " c-banner c-banner--with-img "
              + ( isMain ? 'c-banner--main' : '' )
              + " jumbotron jumbotron-fluid py-4 "
              + " d-flex align-items-center w-100 "
              + " text-"+align+' '+classes
    const content = <React.Fragment>
                      <div className="container">
                        <div className="content-box">
                          {titleBox}
                          {childBox}
                          {childBox ? '' : ctaBox}
                        </div>
                      </div>
                      <div className='c-banner__bkg img-bkg'
                           style={{backgroundImage:"url("+imgUrl+")"}} >
                      </div>
                      <div className='c-banner__bkg img-bkg img-bkg-main'
                           style={{backgroundImage:"url("+mainImgUrl+")"}} >
                      </div>
                    </React.Fragment>
    const elem = link && !cta
                   ? <Link to={link} id={id} className={c+' c-banner--with-link'} >{content}</Link>
                   : <div id={id} className={c}> {content} </div>
    return (
      <React.Fragment>{elem}</React.Fragment>
    )
  }
}
export default Banner
