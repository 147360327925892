import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';
//import Link from "components/Link/";
import Image from 'components/Image'
import { getProdLinkImg } from "helpers/getPath"

export default class Box extends Component {
  render() {
    const { box, prod, fullwidth } = this.props
    const alignment = ' text-left text-sm-left '
    const imgUrl = getProdLinkImg(prod.lang,prod)+'/box/'+box.img
    return (
      <article className={"row c-box mb-3 align-items-center "}>
        {
          (!!box.img) &&
          <div className={"col-12 py-2 col-sm-4 col-md-5 pb-sm-0 "+( fullwidth ? "col-md-4 col-lg-3" : '' )}>
            <Image src={imgUrl}
                   alt={prod.slug}
                   imgStyle="img-fluid "
                   useBkg={false}
                   />
          </div>
        }
        <div className={"col py-2 "+alignment}>
          <h3 className="mb-3">{ReactHtmlParser(box.title)}</h3>
          <div className="c-box__txt">{ReactHtmlParser(box.text)}</div>
        </div>
      </article>
    )
  }
}
