import React, { Component } from 'react'
import Related from '../Prod/parts/Related/'
import Thanks from './parts/Thanks/'

import { LazyLoadComponent } from 'react-lazy-load-image-component';

export default class ProdFull extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowing: false
    }
  }
  closeModalHandler = () => {
    this.setState({ isShowing: false });
  }
  render(){
  const { prod, title } = this.props
  return (
        <div className="p-prod__full">
        <Thanks prod={prod} />
          <LazyLoadComponent>
            <Related prod={prod} title={title} />
          </LazyLoadComponent>
        </div>
      )
    }
}
