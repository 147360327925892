// modifiche e controlli su oggetti e jsons

export function parseIfJsonString(value) {
    return IsJsonString(value) ? JSON.parse( value ) : false
}

export function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function objIsEmpty(o) {
  let obj = parseIfJsonString(o) //se ho una stringa json la converto se no dà false.
  if( typeof obj !== 'object' ) {
    console.error( 'objIsEmpty: '+obj+' is not an object but '+(typeof obj) );
    return false
  }
  for(var prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({}) || JSON.stringify(obj) === undefined
}
