import './style.scss';
import React from 'react'

export default (props) => {
  return (
    <div className="c-loading" >
      <div className="text-center d-block">
        <div className="o-loader o-loader--bullet">Loading...</div>
      </div>
    </div>
  )
}

/*
NOTE:
Se loading è true, ritorno solo il loader stoppando così l'esecuzione.
il loader quindi deve essere messo per primo, subito sotto la valorizzazione
della proprietà "loading".
*/
