import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';

export default class Faq extends Component {
  render() {
    const { faq } = this.props
    return (
      <div className="c-faq">
        <div className="row">
          <div className="col-12">
            <div className="card mb-3 mw-100 w-100">
              <div className="card-header">{ReactHtmlParser(faq.question)}</div>
              <div className="card-body">{ReactHtmlParser(faq.answer)}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
