import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

class Footer extends Component {
  render() {
    const { REACT_APP_NAME, REACT_APP_FORM } = process.env
    const year = new Date().getFullYear();
    const lang = this.props.lang;
    return (
      <footer className="t-footer">
        <div className="text-center my-2">
          {year} &copy; {REACT_APP_NAME}
          <span className="px-2">|</span>
          <a href={REACT_APP_FORM+'info.php?pag=privacy&lang='+lang} target="_blank" rel="noopener noreferrer">Privacy</a>
          <span className="px-2">|</span>
          <a href={REACT_APP_FORM+'info.php?pag=terms&lang='+lang} target="_blank" rel="noopener noreferrer">Terms</a>
        </div>
      </footer>
    )
  }
}
//export default withRouter(Footer);
const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
  }
}
export default connect(mapStateToProps)( withRouter(Footer) );
