import React from 'react'
import Faq from './Item'

export default (props) => {
    const { prod } = props
    // mappo i risultati e ciclo sull'item
    const Faqs = (prod && prod.faqs) &&
                    prod.faqs.map((faq, i) => (
                      <Faq faq={faq} key={i} />
                    ));
    return (
      <section className="c-faq__wrapper">
        {Faqs}
      </section>
    )
}
