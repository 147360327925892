// Store GCLID in Cookie and Send to Hubspot
// Pass Tracking Parameters to a Form on Another Page Using GTM
// http://zackphilipps.com/store-gclid-cookie-send-to-hubspot/

import { setTracker } from 'state/actions'
import { getTracker } from "helpers/querystring"
import { parseIfJsonString } from "helpers/object_and_json"

export function getCookie(name) {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length === 2)
    return parts.pop().split(';').shift();
}

export function setCookie(name, value, days) {
  if( value ) {
    let setDays = ( !isNaN(days) ) ? days : 30;
    let strValue = ( typeof value === 'object' ) ? JSON.stringify(value) : value
    var date = new Date();
    // setta la scadenza in giorni
    date.setTime(date.getTime() + (setDays * 24 * 60 * 60 * 1000));
    var expires = '; expires=' + date.toGMTString();
    document.cookie = name + '=' + strValue + expires + ';path=/';
  }
}

// Qui vengono inizializzati i cookies
export function cookiesInit(componentProps) {
  setTrackerCookies(componentProps)
  setFormCookies()
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// PRIVATES

function setTrackerCookies(componentProps) {
  // recupero la querystring
  const querystring = require('query-string').parse(componentProps.location.search);
  // setto il trackid e descrivo se è google o bing (usando l'helper apposito)
  const tracker = getTracker(querystring);
  // se c'è il trackid nella querystring, lo setto nel cookie
  if(tracker.trackid) setCookie('tracker',tracker)
  const myCookieStr = getCookie('tracker')
  const myCookie = parseIfJsonString(myCookieStr)
  // salvo nello stato globale il cookie per poterlo passare alla form
  if( myCookie && myCookie.trackid ) componentProps.dispatch( setTracker( myCookie ) )
}

// setta tutti i cookie che passano i valori alla form prendendo la stringa randomica
function setFormCookies() {
  let abTest = getCookie('abTest')
  if( !abTest || abTest==='{}' ) {
    setCookie('abTest',ABTestCreateRamdoms())
  }
}

// qui elenco tutti i valori in get che passerano ai cookie
// e che a loro volta verranno passato alla form.
// sono dei valori decisi randomicamente da una lista (TODO: creare lista con Marco)
function ABTestCreateRamdoms() {
  return {
    //btnlabel : 'Procedi',
    //btnbkg : '#0f0',
    //btncolor : '#00f',
    //btnbordercolor : '#ff0'
  }
}
